import React from 'react';
import PropTypes from 'prop-types';
import { StyledFirebaseAuth } from 'react-firebaseui';

import { firebaseApp } from '../../firebase/config';

const FirebaseLogin = ({ uiConfig, ...props }) => {
  return (
    <StyledFirebaseAuth
      uiConfig={uiConfig}
      firebaseAuth={firebaseApp?.auth()}
      {...props}
    />
  );
};

FirebaseLogin.propTypes = {
  uiConfig: PropTypes.object.isRequired,
};

export default FirebaseLogin;
