import React, { useEffect } from 'react';
import FirebaseLogin from '../../../components/FirebaseAuth/FirebaseLogin';
import { useFirebaseAuth } from '../../../components/FirebaseAuth';
import { navigate } from 'gatsby';
import firebase from '../../../firebase/config';
import MembersLayout from '../../../components/Members/Layout';
import Container from '../../../components/Container';
import SEO from '../../../components/seo';

let firebaseui;

if (typeof window !== 'undefined') {
  firebaseui = require('firebaseui');
}

const firebaseUiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  // signInSuccessUrl: '/members/login?signedIn',
  credentialHelper: firebaseui?.auth.CredentialHelper.NONE,
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase?.auth.EmailAuthProvider.PROVIDER_ID],
  // credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

const MembersLogin = ({ location }) => {
  const { isLoggedIn, user } = useFirebaseAuth();
  useEffect(() => {
    if (user && isLoggedIn) {
      if (typeof navigator !== 'undefined') {
        let userData = {};
        fetch('https://ipapi.co/json')
          .then(async data => {
            userData = await data.json();
          })
          .finally(() => {
            firebase
              .firestore()
              .collection('sessions')
              .add({
                uid: user?.uid,
                dateTime: firebase.firestore.Timestamp.fromDate(new Date()),
                userAgent: navigator.userAgent,
                ...userData,
              })
              .finally(() => {
                navigate(`/members${location.search}`);
              });
          });
      }
    }
  }, [isLoggedIn, user]);
  return (
    <MembersLayout title="Members login">
      <SEO title="Members login" />
      <Container py={[3, 4, 5]}>
        <FirebaseLogin uiConfig={firebaseUiConfig} />
      </Container>
    </MembersLayout>
  );
};

MembersLogin.propTypes = {};

export default MembersLogin;
